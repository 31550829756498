<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-center align-items-center" style="height:100vh" v-if="loading">
      <span
        class="spinner-border spinner-border-lg"
      ></span>
    </div>
    <div class="row" v-else>
      <div class="row g-2">
        <header class="jumbotron">
          <h2>Stats</h2>
        </header>
        <div class="row g-0" v-show="filters.visible">
          <div class="col-md-3 g-1">Prelude</div>
          <div class="col-md-9 g-1"> 
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.prelude" id="inlineRadio1" value="-1">  
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.prelude" name="inlineRadio1" value="0"> 
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.prelude" name="inlineRadio1" value="1"> 
            </div>  
          </div>
          <div class="col-md-3 g-1">Venus</div>
          <div class="col-md-9 g-1"> 
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.venus" id="inlineRadio2" value="-1">  
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.venus" name="inlineRadio2" value="0"> 
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.venus" name="inlineRadio2" value="1"> 
            </div>  
          </div>
          <div class="col-md-3 g-1">Colonies</div>
          <div class="col-md-9 g-1"> 
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.colonies" id="inlineRadio3" value="-1">  
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.colonies" name="inlineRadio3" value="0"> 
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.colonies" name="inlineRadio3" value="1"> 
            </div>  
          </div>
          <div class="col-md-3 g-1">Turmoil</div>
          <div class="col-md-9 g-1"> 
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.turmoil" id="inlineRadio4" value="-1">  
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.turmoil" name="inlineRadio4" value="0"> 
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="filters.turmoil" name="inlineRadio4" value="1"> 
            </div>  
          </div>
          <div class="col-md-3 g-1">Jogadores</div>
          <div class="col-md-3 g-1"> 
            <select id="inputPlayers" class="form-select" v-model="filters.playerCount">
              <option>Todos</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <h5>Corporations <font-awesome-icon icon="info-circle" style="cursor: pointer" @click="filters.visible = !filters.visible"/></h5>
          
          <div class="row g-3">
            <div class="col-md-12">
              <table class="table table-sm table-stripped table-hover">
                <thead>
                  <tr>
                    <th :class="[sort.corp, 'sortable']">Corp</th>
                    <th :class="[sort.winrate, 'sortable']">Winrate</th>
                    <th :class="[sort.win, 'sortable']">Vitorias</th>
                    <th :class="[sort.point, 'sortable']">Pontos</th>
                    <th :class="[sort.games, 'sortable']">Jogos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="corp in corporationStats" :key="corp">
                    <td>{{corp.name}}</td>
                    <td>{{`${Math.round(corp.winner/corp.games *100)}%`}}</td>
                    <td>{{corp.winner}}</td>
                    <td>{{Math.round(corp.points/corp.games)}}</td>
                    <td>{{corp.games}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <h5>Jogadores <font-awesome-icon icon="info-circle" style="cursor: pointer" @click="filters.visible = !filters.visible"/></h5>
          <div class="row g-3">
            <div class="col-md-12">
              <table class="table table-sm table-stripped table-hover">
                <thead>
                  <tr>
                    <th :class="[sortp.name, 'sortable']">Jogador</th>
                    <th :class="[sortp.winner, 'sortable']">Vitorias</th>
                    <th :class="[sortp.second, 'sortable']">2o Lugar</th>
                    <th :class="[sortp.games, 'sortable']">Jogos</th>
                    <th :class="[sortp.winrate, 'sortable']">Winrate</th>
                    <th :class="[sortp.success, 'sortable']">% Sucesso</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="player in playerStats" :key="player">
                    <td>{{player.name}}</td>
                    <td>{{player.winner}}</td>
                    <td>{{player.second}}</td>
                    <td>{{player.games}}</td>
                    <td>{{`${Math.round(player.winner/player.games *100)}%`}}</td>
                    <td>{{`${Math.round(player.winnerPoints/player.games *100)}%`}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsService from "../services/stats.service";

export default {
  name: "Stats",
  data() {
    return {
      loading: true,
      content: '',
      data: {},
      filters: {
        playerCount: 'Todos',
        venus: 0,
        prelude: 0,
        turmoil: 0,
        colonies: 0,
        visible: false
      },
      sort: {
        corp: 'up',
        win: 'down',
        point: 'down',
        games: 'down'
      },
      sortp: {
        name: 'down',
        winner: 'down',
        second: 'down',
        games: 'down',
        winrate: 'down',
        success: 'down'
      }
    };
  },
  computed: {
    corporationStats(){
      let arr = Object.keys(this.data.corporations).map( k => {
        return this.data.corporations[k].reduce( (acc, v) => {
          if( 
            (this.filters.venus == 0 || (this.filters.venus == 1 && v.venus) || (this.filters.venus == -1 && !v.venus)) &&
            (this.filters.prelude == 0 || (this.filters.prelude == 1 && v.prelude) || (this.filters.prelude == -1 && !v.prelude)) &&
            (this.filters.turmoil == 0 || (this.filters.turmoil == 1 && v.turmoil) || (this.filters.turmoil == -1 && !v.turmoil)) &&
            (this.filters.colonies == 0 || (this.filters.colonies == 1 && v.colonies) || (this.filters.colonies == -1 && !v.colonies)) &&
            (this.filters.playerCount == 'Todos' || (this.filters.playerCount == v.pCount))
          ){
            acc.winner += v.winner ? 1 : 0;
            acc.points += v.points;
            acc.games++;
          }
          return acc;
        }, 
        {
          name: k,
          winner: 0,
          points: 0,
          games: 0,
        }
      );

      });
      
      return [...arr].filter(c => c.games > 0).sort((a,b) => {
        if(b.winner/b.games > a.winner/a.games) return 1;
        if(b.winner/b.games < a.winner/a.games) return -1;
        if(b.winner > a.winner) return 1;
        if(b.winner < a.winner) return -1;
        if(b.games > a.games) return 1;
        else return -1;
      });
    },
    playerStats(){
      let arr = Object.keys(this.data.players).map( k => {
        return this.data.players[k].reduce( (acc, v) => {
          if( 
            (this.filters.venus == 0 || (this.filters.venus == 1 && v.venus) || (this.filters.venus == -1 && !v.venus)) &&
            (this.filters.prelude == 0 || (this.filters.prelude == 1 && v.prelude) || (this.filters.prelude == -1 && !v.prelude)) &&
            (this.filters.turmoil == 0 || (this.filters.turmoil == 1 && v.turmoil) || (this.filters.turmoil == -1 && !v.turmoil)) &&
            (this.filters.colonies == 0 || (this.filters.colonies == 1 && v.colonies) || (this.filters.colonies == -1 && !v.colonies)) &&
            (this.filters.playerCount == 'Todos' || (this.filters.playerCount == v.pCount))
          ){
            acc.winner += v.winner ? 1 : 0;
            acc.winnerPoints += v.winner ? (v.pCount * 0.5) : 0;
            acc.second += v.second;
            acc.games++;
          }
          return acc;
        }, 
        {
          name: k,
          openskill: this.data.players[k][0]?.openskill,
          winner: 0,
          winnerPoints: 0,
          second: 0,
          games: 0,
        }
      );

      });
      
      return [...arr].filter(c => c.games > 0).sort((a,b) => {
        if(b.openskill > a.openskill) return 1;
        if(b.openskill < a.openskill) return -1;
        if(b.winner > a.winner) return 1;
        if(b.winner < a.winner) return -1;
        if(b.points/b.games > a.points/a.games) return 1;
        if(b.points/b.games < a.points/a.games) return -1;
        if(b.games > a.games) return 1;
        else return -1;
      });
    }
  },
  mounted() {
    StatsService.getStats().then(
      (response) => {
        this.data = response.data;
        this.loading = false;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      });
  },
};
</script>

<style scoped>

.sortable.up::after {
 content: "\23f6";
}

.sortable.down::after {
  content: "\23f7"; 
}

</style>